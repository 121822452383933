<template>
  <div class="content">
    <!-- Start Single Progress Bar -->
    <div class="progress_wrapper">
      <h2 class="section-title text-left">企业人员分布</h2>
      <div class="single-skill">
        <p>开发人员</p>
        <div
          class="progress progress-height-6 progress-bar-bg--1 progress-label-top"
        >
          <div
            class="progress-bar wow fadeInLeft progress-bar-bg--1"
            role="progressbar"
            style="width: 50%;"
            aria-valuenow="50"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <span class="percent-label">50%</span>
          </div>
        </div>
      </div>

      <div class="single-skill">
        <p>业务人员</p>
        <div
          class="progress progress-height-6 progress-bar-bg--1 progress-label-top"
        >
          <div
            class="progress-bar wow fadeInLeft progress-bar-bg--1"
            role="progressbar"
            style="width: 25%;"
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <span class="percent-label">25%</span>
          </div>
        </div>
      </div>

      <div class="single-skill">
        <p>运维人员</p>
        <div
          class="progress progress-height-6 progress-bar-bg--1 progress-label-top"
        >
          <div
            class="progress-bar wow fadeInLeft progress-bar-bg--1"
            role="progressbar"
            style="width: 25%;"
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <span class="percent-label">25%</span>
          </div>
        </div>
      </div>
    </div>
    <!-- End Single Progress Bar -->
  </div>
</template>

<script>
  export default {};
</script>

<style lang="scss" scoped></style>
