<template>
  <div class="about-page">
    <div class="rn-story-area d-flex bg_color--3" id="about">
      <div
        class="rn-story-left w-50 rn-story-bg-wrapper"
        :style="{ backgroundImage: 'url(' + aboutSrc + ')' }"
      ></div>
      <div class="rn-story-right w-50 d-flex align-items-center">
        <div
          class="story-style--3 story-content text-left rn-plr--160 section-ptb-xl"
        >
          <div class="content text-left">
            <h2>
              嘉晟科技<br />
              科技创新团队
            </h2>
            <p>
              嘉晟科技一支汇聚了各路精英的卓越团队，成员们来自不同的专业背景，却因对科技创新的共同热爱和追求而凝聚在一起。他们中有经验丰富的科研老将，如同深邃夜空中的北斗星，以其多年积累的知识和敏锐的洞察力为团队指引方向。
            </p>
            <p>
              嘉晟科技创新团队的成果，不仅为公司带来了显著的经济效益，更提升了公司在行业内的核心竞争力。他们研发的创新产品和技术解决方案，在市场上引起了广泛关注和好评，为公司赢得了客户的信赖和尊重。他们是公司发展的强大引擎，推动着公司在科技创新的征程中不断向前，向着更高的目标奋勇迈进。在未来，他们将继续秉持创新精神，书写公司发展的辉煌篇章，为行业的进步贡献更多的智慧和力量。
            </p>
            <div
              class="story-btn  wow newFadeInUp"
              data-wow-duration="1s"
              data-wow-delay="1000ms"
            >
              <router-link to="/contact">联系我们</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Story Area -->
    <!-- Start Skill Area -->
    <div class="rn-skill-area home-one-skill d-flex bg_color--3" id="skill">
      <div class="rn-skill-right w-50 d-flex align-items-center">
        <div class="text-left rn-skill rn-plr section-ptb-xl">
          <Skills />
        </div>
      </div>
      <div
        class="rn-skill-left w-50 rn-skill-bg-wrapper"
        :style="{ backgroundImage: 'url(' + skillSrc + ')' }"
      ></div>
    </div>
    <!-- End Skill Area -->
  </div>
</template>

<script>
  import Skills from "../../components/skill/Skills";
  export default {
    components: {
      Skills,
    },
    data() {
      return {
        aboutSrc: require("../../assets/img/portfolio/about-image.jpg"),
        skillSrc: require("../../assets/img/portfolio/my-skill.jpg"),
      };
    },
  };
</script>

<style lang="scss" scoped>
  .about-page {
    .rn-skill-right {
      @media only screen and (max-width: 767px) {
        order: 2;
      }
    }
  }
</style>
